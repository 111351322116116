<template>
    <div class="bard mb-12" ref="CookieDeclarationRef"></div>
</template>

<script setup>
import { useCookiebot } from "@ambitiondev/vue-cookiebot";
import { onMounted, ref } from "vue";

const CookieDeclarationRef = ref(null);

const { cookieDeclaration } = useCookiebot();

onMounted(() => {
    cookieDeclaration(CookieDeclarationRef);
});
</script>
