<template>
  <a :href="'mailto:' + rot13(email)" class="hover:tw-underline">{{ rot13(email) }}</a>
</template>

<script setup>

const props = defineProps({
    emailA: {
        type: String,
        required: true
    },
    emailB: {
        type: String,
        required: true
    },
    emailC: {
        type: String,
        required: true
    }
})

const email = props.emailC + props.emailB + props.emailA;
const rot13 = (str) => {
          return str.replace(/[a-zA-Z]/g, function(c) {
            return String.fromCharCode(
              (c <= 'Z' ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26
            );
          });
        }
</script>