import { ref, inject, toValue } from "vue";
import { useReCaptcha } from "vue-recaptcha-v3";
import { useTrackingEvents } from "./trackingEvents";

export function useFormHandling(options) {

  const { formToHandle, formData, recaptchaAction, action, gtmEventName, eventData, product, resetFormCallBack, sateliteEventName } = options;

  const sending = ref(false);
  const success = ref(false);
  const failed = ref(false);
  const failedMessage = ref("");
  const responseData = ref(null);
  const axios = inject("axios");
  const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

  const reset = ()=>{
    sending.value = false;
    success.value = false;
    failed.value = false;

  }

  const recaptcha = async () => {
    sending.value = true;
    success.value = false;
    failed.value = false;
    failedMessage.value = "";

    const formTransformed = transformFormData(formData, action);

    const urlParams = new URLSearchParams(window.location.search);
    const testMode = urlParams.get("test") === "true";
    if (testMode) {
      useTrackingEvents(gtmEventName, sateliteEventName, eventData);
      sending.value = false;
      resetFormCallBack();
      return;
    }

    await recaptchaLoaded();
    const token = await executeRecaptcha(recaptchaAction);
    axios
      .post(
        "/",
        {
          action,
          CRAFT_CSRF_TOKEN: window.csrfTokenValue,
          "g-recaptcha-response": token,
          ...formTransformed,
        },
        {
          headers: { "content-type": "application/x-www-form-urlencoded" },
        }
      )
      .then((response) => {
        sending.value = false;

        if (action!=='contact-form/send' && !response.data.success) {
          console.log("error:", response.data);
          failed.value = true;
          failedMessage.value = response.data.errors.join("<br>");
          window.bugsnag.notify("form failed" + response.data.errors.join(","));
          return;
        }

        useTrackingEvents(gtmEventName, sateliteEventName, eventData);
        resetFormCallBack();
        success.value = true;
        responseData.value = response.data;
      })
      .catch(function (error) {
        if (error?.response?.data?.errors) {
          sending.value = false;
          failed.value = true;
          const errors = error.response.data.errors;
          failedMessage.value = Object.values(errors).flat()[0];
          return;
        }

        console.log(error);
        sending.value = false;
        failed.value = true;
        failedMessage.value = "Please try again.";
        window.bugsnag.notify(error);
      });


  };

  const buttonClicked = () => {



    if (formToHandle.value.reportValidity()) {
      recaptcha();
    }
  }

  return { sending, success, failed, failedMessage, responseData, recaptcha, buttonClicked, reset };
}

function transformFormData(formData, action) {

    const formTransformed = { ...formData };


    // maybe not needed on this site

    // if (action === 'contact-form/send') {

    //   Object.keys(formTransformed).forEach((key) => {
    //     if (key === 'email') {
    //       formTransformed[`fromEmail`] = formTransformed[key];
    //       delete formTransformed[key];
    //     }
    //     formTransformed[`message[${key}]`] = formTransformed[key];
    //     delete formTransformed[key];
    //   });

    //   return formTransformed;
    // }

    // console.log(formTransformed,formData);
    // Object.keys(formTransformed).forEach((key) => {
    //   formTransformed[`fields[${key}]`] = formTransformed[key];
    //   delete formTransformed[key];
    // });

    return formTransformed;
}
