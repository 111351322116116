import "../css/ledingham-chalmers.css";
import "../css/btk.css";

import { cookieBot,useCookiebot  } from "@ambitiondev/vue-cookiebot";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
  enabledReleaseStages: ["production", "staging"],
  releaseStage: process.env.NODE_ENV,
});

if (typeof userId !== "undefined" && userId && typeof userName !== "undefined" && userName) {
  Bugsnag.setUser(userId, "", userName);
}

const bugsnagVue = Bugsnag.getPlugin("vue");

import { createApp, defineAsyncComponent, ref, watch } from "vue";
import VueGoogleMaps from "vue-google-maps-community-fork";
import axios from "axios";
import VueAxios from "vue-axios";
import MobileNavigation from "./Components/Navigation/MobileNavigation.vue";
import ValuationForm from "./Components/Forms/ValuationForm.vue";


import CookiebotDeclartion from "./Components/CookieBot/Declaration.vue"
import CookiebotRenew from "./Components/CookieBot/Renew.vue";
import ConsentBanner from "./Components/CookieBot/ConsentBanner.vue";
import ShowEmail from "./Components/ShowEmail.vue";


const ViewingForm = defineAsyncComponent(()=> import("./Components/Forms/ViewingForm.vue"));
const BuyingForm = defineAsyncComponent(()=> import("./Components/Forms/BuyingForm.vue"));
const SellingForm = defineAsyncComponent(()=> import("./Components/Forms/SellingForm.vue"));
const GeneralEnquiryForm = defineAsyncComponent(()=> import("./Components/Forms/GeneralEnquiryForm.vue"));
const PropertyTabs = defineAsyncComponent(()=> import("./Components/Properties/PropertyTabs.vue"));
const PropertyDetail = defineAsyncComponent(()=> import("./Components/Properties/PropertyDetail.vue"));
const LatLong = defineAsyncComponent(()=> import("./Components/Maps/LatLong.vue"));
const PropertyFilter = defineAsyncComponent(()=> import("./Components/Filters/PropertyFilter.vue"));

const app = createApp({
  components: {
    ValuationForm,
    MobileNavigation,
    PropertyTabs,
    PropertyDetail,
    ViewingForm,
    BuyingForm,
    SellingForm,
    GeneralEnquiryForm,
    LatLong,
    PropertyFilter,
    CookiebotDeclartion,
    CookiebotRenew,
    ConsentBanner,
    ShowEmail,
  },
  setup() {
    const showing = ref(false);
    const showValuationForm = ref(false);
    const showViewingForm = ref(false);
    const showBuyingForm = ref(false);
    const showSellingForm = ref(false);


    watch(showing, (newVal) => {
      if (newVal) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    });

    return {
      showing,
      showValuationForm,
      showViewingForm,
      showBuyingForm,
      showSellingForm,
    };
  },
});

import { VueReCaptcha  } from "vue-recaptcha-v3";
  VueReCaptcha.install(app, {
    siteKey: import.meta.env.VITE_RECAPTCHA_SITEKEY,
    loaderOptions: {
      autoHideBadge: true,
    }
  });


app.use(bugsnagVue)
.use(VueAxios, axios)
.use(cookieBot, {
  cookieBotId: import.meta.env.VITE_COOKIEBOT_DOMAIN_GROUP_ID,
})
.use(VueGoogleMaps, {
  load: {
    key: import.meta.env.VITE_MAP_KEY,
    v: 3.57,
    loading: 'async',
  },
})
.provide("axios", app.config.globalProperties.axios)
.mount("#app");
