export function useTrackingEvents(gtmEventName, sateliteEventName, eventData) {
  const urlParams = new URLSearchParams(window.location.search);
  const testMode = urlParams.get("test") === "true";
  if (testMode) {
    const gtmEventElement = document.querySelector("[name='GTMEventName']");
    if (gtmEventElement) {
      gtmEventElement.remove();
    }

    const sateliteEventElement = document.querySelector("[name='SateliteEventName']");
    if (sateliteEventElement) {
      sateliteEventElement.remove();
    }

    const eventDataElement = document.querySelector("[name='EventData']");
    if (eventDataElement) {
      eventDataElement.remove();
    }

    // create meta tags for ghost inspector
    const meta = document.createElement("meta");
    meta.name = "GTMEventName";
    meta.content = gtmEventName;
    document.getElementsByTagName("head")[0].appendChild(meta);

    const meta2 = document.createElement("meta");
    meta2.name = "SateliteEventName";
    meta2.content = sateliteEventName;
    document.getElementsByTagName("head")[0].appendChild(meta2);

    const meta3 = document.createElement("meta");
    meta3.name = "EventData";
    meta3.content = JSON.stringify(eventData);
    document.getElementsByTagName("head")[0].appendChild(meta3);

    // and for us humans lets use the console
    console.log("%c GTMEventName:", "background: #222; color: #bada55");
    console.log(gtmEventName);
    console.log("%c Satellite event name:", "background: #222; color: #bada55");
    console.log(sateliteEventName);
    console.log("%c Event data:", "background: #222; color: #bada55");
    console.log(eventData);

    return;
  }

  // for rolex
  if (sateliteEventName !== null) {
    if (typeof window._satellite !== "undefined") {
      window._satellite.track(sateliteEventName);
    } else {
      console.log("Would have pushed to satelite:");
      console.log("track " + sateliteEventName);
    }
  }

  // for tag manager
  if (gtmEventName !== null && eventData !== null) {
    if (typeof window.dataLayer !== "undefined") {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: gtmEventName,
        ...eventData,
      });
    } else {
      console.log("Would have pushed to GTM:");
      console.log({
        event: gtmEventName,
        ...eventData,
      });
    }
  }
}
