<template>
  <Dialog :open="show" @close="done" class="tw-relative tw-z-[50000]">
    <div class="tw-fixed tw-inset-0 tw-bg-black/30" aria-hidden="true" />
    <div class="tw-fixed tw-inset-0 tw-flex tw-w-screen tw-items-center tw-justify-center tw-p-4">
      <DialogPanel class="tw-w-full tw-max-w-3xl tw-bg-white tw-p-8 tw-overflow-y-scroll tw-max-h-full" id="valuation-form-panel">
        <h2>Request a free valuation</h2>
        <p>Our team can give you a free valuation of what your home’s worth. Fill in the form below, or if you’d prefer, give us a shout on 01224 632500.</p>

        <div class="form-submitted" v-if="success">
          <p>
            Thank you for giving your consent to Ledingham Chalmers LLP to use your personal information for the purposes of responding to your enquiry. If you would like to know more about how we use your data please read our
            <a href="/privacy">Privacy Notice.</a>
          </p>
          <button type="button" class="btn btn-inverse" @click="done">Close</button>
        </div>

        <div v-else>
          <form ref="formRef">
            <div>
              <label for="forename">First Name</label>
              <input id="forename" type="text" v-model="form.firstname" required />
            </div>
            <div>
              <label for="surname">Last Name</label>
              <input id="surname" type="text" v-model="form.lastname" required />
            </div>
            <div>
              <label for="email">Email</label>
              <input id="email" type="email" v-model="form.email" required />
            </div>
            <div>
              <label for="phone">Phone</label>
              <input id="phone" type="text" v-model="form.phone" required />
            </div>
            <div>
              <label for="address">Your address</label>
              <input id="address" type="text" v-model="form.address" required />
            </div>
            <div>
              <label for="postcode">Your postcode</label>
              <input id="postcode" type="text" v-model="form.postcode" required />
            </div>
            <div>
              <label for="type">Type of property</label>
              <input id="type" type="text" v-model="form.type" required />
            </div>
            <div>
              <label for="bedrooms">Number of bedrooms</label>
              <input id="bedrooms" type="text" v-model="form.bedrooms" required />
            </div>
            <div>
              <label for="notes"
                >Notes or comments?
                <small>(optional)</small>
              </label>
              <textarea id="notes" name="notes" rows="4" v-model="form.comments"></textarea>
            </div>
            <slot></slot>

            <div v-show="failed" v-cloak class="errors">
              <ul class="errors">
                <li class="error">{{ failedMessage }}</li>
              </ul>
            </div>

            <div class="buttons">
              <button type="button" class="btn" @click="buttonClicked" :disabled="sending"><span v-if="!sending">Request a free valution</span><span v-else>Sending...</span></button>
              <button type="button" class="btn btn-inverse" @click="done">Close</button>
            </div>
          </form>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import { ref } from "vue";
import { useFormHandling } from "../../formHandling.js";
import { Dialog, DialogPanel } from "@headlessui/vue";

const emit = defineEmits(['close']);

const formRef = ref(null);
const form = ref({
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  address: "",
  postcode: "",
  type: "",
  bedrooms: "",
  comments: "",
});

const props = defineProps({
  show: {
    required: true,
    type: Boolean,
  },
});

const options = {
  formToHandle: formRef,
  formData: form.value,
  recaptchaAction: "valuationForm",
  action: "properties/enquiries/valuation-enquiry",
  gtmEventName: null,
  sateliteEventName: null,
  resetFormCallBack: () => {},
};

const done = () => {
  form.value = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    address: "",
    postcode: "",
    type: "",
    bedrooms: "",
    comments: "",
  };

  reset();
  emit('close');

};

const { success, failed, sending, failedMessage, recaptcha, responseData, buttonClicked,reset } = useFormHandling(options);
</script>
