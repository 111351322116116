<template>
    <a href="#"

            @click.prevent="showConsent"
            >Change your consent</a
        >

</template>

<script setup>
import { useCookiebot } from "@ambitiondev/vue-cookiebot";

const { renew } = useCookiebot();

const showConsent = () => {
    renew();
};
</script>
